<template>
  <Layout>
    <pre>
VUE_APP_MOCK_COGNITO={{ VUE_APP_MOCK_COGNITO }}
API_BASE_URL={{ API_BASE_URL }}
    </pre>
  </Layout>
</template>

<script>
import Layout from '@layouts/main.vue'

export default {
  page() {
    return {
      title: 'Debug',
      meta: [
        {
          name: 'description',
          content: `Debug info`,
        },
      ],
    }
  },
  components: { Layout },
  data() {
    return {
      VUE_APP_MOCK_COGNITO: process.env.VUE_APP_MOCK_COGNITO,
      API_BASE_URL: process.env.API_BASE_URL,
    }
  },
}
</script>
